import auth from "../libs/auth";

const uri = `https://uatapi.libertycatalogue.com/`;

class APIUtil {
  getBaseUrl() {
    return process.env.REACT_APP_API_URL;
  }

  async callPostAPI(apiUrl, payload = {}, baseUrl) {
    let options = await this.postOptions(payload);
    return this.callApi(apiUrl, options, baseUrl);
  }

  async callGetAPI(apiUrl, payload, baseUrl) {
    let options = await this.getOptions(payload);
    return this.callApi(apiUrl, options, baseUrl);
  }

  callApi(apiUrl, options, baseUrl) {
    let url = `${baseUrl ? baseUrl : this.getBaseUrl()}${apiUrl}`;
    return fetch(url, options);
  }

  async postOptions(payload) {
    const options = {};
    options.method = "POST";
    options.body = JSON.stringify(payload);
    options.headers = await this.getHeadersPost();

    return options;
  }

  async getOptions() {
    const options = {};
    options.method = "GET";
    options.headers = await this.getHeadersPost();

    return options;
  }

  async getHeadersPost() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    let userKey = await auth.userKey;
    if (userKey) {
      headers.set("Authorization", "Bearer " + userKey);
    }

    return headers;
  }
}

export default new APIUtil();
